import { useEffect, useMemo, useState } from 'react';
import { useLocation, matchPath, PathMatch, generatePath } from 'react-router-dom';
import { RoutePath as rp } from 'src/router';

import { useSelector, useDispatch } from 'src/store';
import { setBreadcrumbsNames } from 'src/store/slices/breadcrumbsSlice';
import { breadcrumbsNamesMapSelector } from 'src/store/selectors/breadcrumbsSelector';

import { Breadcrumb, Breadcrumbs } from '@itm/shared-frontend/lib/components';

type BreadcrumbConfig = {
  name: string | null | undefined;
  parentPath: string | null;
};

type ExtendedBreadcrumbsConfig = BreadcrumbConfig & Pick<PathMatch<string>, 'pathname'>;

type BreadcrumbConfigMap = {
  [key: string]: BreadcrumbConfig;
};

type UseSetBreadcrumbsNamesProps = Readonly<{
  routePath: string | null;
  value: string | null | undefined;
  isClearOnUnmount?: boolean;
}>;

export const useSetBreadcrumbsNames = ({ routePath, value, isClearOnUnmount = true }: UseSetBreadcrumbsNamesProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!value || !routePath) return;
    dispatch(setBreadcrumbsNames({ [routePath]: value }));
  }, [routePath, value, dispatch]);

  useEffect(
    () => () => {
      if (!isClearOnUnmount || !routePath) return;
      dispatch(setBreadcrumbsNames({ [routePath]: null }));
    },
    [routePath, isClearOnUnmount, dispatch],
  );
};

const getBreadcrumbs = (pathToMatch: string, breadcrumbsConfig: BreadcrumbConfigMap): Breadcrumb[] => {
  const matchedList = Object.keys(breadcrumbsConfig).reduce<PathMatch<string>[]>((acc, route) => {
    const matched = matchPath(route, pathToMatch);
    return matched ? [...acc, matched] : acc;
  }, []);

  if (!matchedList.length) return [];

  const pathMatch =
    // try to find the full match
    matchedList.find((m) => !m.pattern.path.endsWith('/*')) ||
    // if not found, find the longest wildcard match
    matchedList.toSorted((a, b) => b.pattern.path.length - a.pattern.path.length)[0];

  const routeParams = pathMatch.params;

  let revertedBreadcrumbsList: Breadcrumb[] = [];
  let extendedConfig: ExtendedBreadcrumbsConfig | null = {
    pathname: pathMatch.pathname,
    ...breadcrumbsConfig[pathMatch.pattern.path],
  };

  while (extendedConfig) {
    const { name, pathname, parentPath }: ExtendedBreadcrumbsConfig = extendedConfig;

    if (name) {
      revertedBreadcrumbsList.push({ name, to: pathname });
    } else {
      revertedBreadcrumbsList = [];
    }

    extendedConfig = parentPath
      ? { ...breadcrumbsConfig[parentPath], pathname: generatePath(parentPath, routeParams) }
      : null;
  }

  return revertedBreadcrumbsList.reverse();
};

function BreadcrumbsTrail() {
  const { pathname } = useLocation();
  const bcNamesMap = useSelector(breadcrumbsNamesMapSelector);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const isHidden = useMemo(
    () =>
      Boolean(
        matchPath(rp.root, pathname) ||
          matchPath(rp.loginRedirect, pathname) ||
          matchPath(rp.permissionDenied, pathname),
      ),
    [pathname],
  );

  const config: BreadcrumbConfigMap = useMemo(
    () => ({
      [rp.root]: { name: 'Employer Hub', parentPath: null },

      // CONFIGURATION
      [rp.configurationRoot]: { name: 'Configuration', parentPath: rp.root },

      // Configuration / Template Definitions
      [rp.configurationTemplateDefinitionList]: { name: 'Template Definitions', parentPath: rp.configurationRoot },
      // Configuration / Template Definitions / Edit / Upload Template Type
      [`${rp.configurationTemplateDefinitionEditTemplateTypeUpdate}/*`]: {
        name: bcNamesMap[rp.configurationTemplateDefinitionEditTemplateTypeUpdate],
        parentPath: rp.configurationTemplateDefinitionList,
      },
      // Configuration / Template Definitions / Edit / Salary Types
      [`${rp.configurationTemplateDefinitionEditSalaryTypesRoot}/*`]: {
        name: bcNamesMap[rp.configurationTemplateDefinitionEditSalaryTypesRoot],
        parentPath: rp.configurationTemplateDefinitionList,
      },

      // Configuration / General Settings
      [rp.configurationGeneralSettingsList]: { name: 'General Settings', parentPath: rp.configurationRoot },
      [rp.configurationGeneralSettingsViewEmployer]: {
        name: bcNamesMap[rp.configurationGeneralSettingsViewEmployer],
        parentPath: rp.configurationGeneralSettingsList,
      },
      [rp.configurationGeneralSettingsEditEmployer]: {
        name: bcNamesMap[rp.configurationGeneralSettingsEditEmployer],
        parentPath: rp.configurationGeneralSettingsList,
      },

      // Configuration / Validations
      [rp.configurationValidationsList]: { name: 'All Validations', parentPath: rp.configurationRoot },
      // Configuration / Validations / View
      [rp.configurationValidationViewRoot]: {
        name: bcNamesMap[rp.configurationValidationViewRoot],
        parentPath: rp.configurationValidationsList,
      },
      [rp.configurationValidationViewDetail]: {
        name: 'Validation Detail',
        parentPath: rp.configurationValidationViewRoot,
      },
      [rp.configurationValidationViewAudit]: {
        name: 'Audit',
        parentPath: rp.configurationValidationViewRoot,
      },

      // Configuration / Validations / Edit
      [rp.configurationValidationEditRoot]: {
        name: bcNamesMap[rp.configurationValidationEditRoot],
        parentPath: rp.configurationValidationsList,
      },
      [rp.configurationValidationEditDetail]: {
        name: 'Validation Detail',
        parentPath: rp.configurationValidationEditRoot,
      },
      [rp.configurationValidationEditAudit]: {
        name: 'Audit',
        parentPath: rp.configurationValidationEditRoot,
      },

      // Configuration / Payrolls
      [rp.configurationPayrollsList]: { name: 'All Payrolls', parentPath: rp.configurationRoot },
      [rp.configurationPayrollsAdd]: { name: 'Add New Payroll', parentPath: rp.configurationRoot },

      // Configuration / Payrolls / View
      [rp.configurationPayrollViewRoot]: {
        name: bcNamesMap[rp.configurationPayrollViewRoot],
        parentPath: rp.configurationPayrollsList,
      },
      [rp.configurationPayrollViewDetail]: { name: 'Payroll Detail', parentPath: rp.configurationPayrollViewRoot },
      [rp.configurationPayrollViewPeriod]: { name: 'Payroll Period', parentPath: rp.configurationPayrollViewRoot },

      // Configuration / Payrolls / Edit
      [rp.configurationPayrollEditRoot]: {
        name: bcNamesMap[rp.configurationPayrollEditRoot],
        parentPath: rp.configurationPayrollsList,
      },
      [rp.configurationPayrollEditDetail]: { name: 'Payroll Detail', parentPath: rp.configurationPayrollEditRoot },
      [rp.configurationPayrollEditPeriod]: { name: 'Payroll Period', parentPath: rp.configurationPayrollEditRoot },

      // DATA MANAGEMENT
      [rp.dataManagementRoot]: { name: 'Data Management', parentPath: rp.root },

      // Data Management / Data Upload History
      [rp.dataManagementUploadHistoryList]: { name: 'Data Upload History', parentPath: rp.dataManagementRoot },
      [rp.dataManagementUploadHistoryUploadData]: {
        name: 'Upload Data',
        parentPath: rp.dataManagementUploadHistoryList,
      },
      [rp.dataManagementUploadHistoryDetailsRoot]: {
        name: bcNamesMap[rp.dataManagementUploadHistoryDetailsRoot],
        parentPath: rp.dataManagementUploadHistoryList,
      },
      [rp.dataManagementUploadHistoryDetailsSummary]: {
        name: 'Upload Summary',
        parentPath: rp.dataManagementUploadHistoryDetailsRoot,
      },
      [rp.dataManagementUploadHistoryDetailsAudit]: {
        name: 'Audit',
        parentPath: rp.dataManagementUploadHistoryDetailsRoot,
      },

      // Data Management / Validation Helper
      [rp.dataManagementValidationHelperList]: { name: 'Validation Helper', parentPath: rp.dataManagementRoot },

      // Data Management / Member Search
      [rp.dataManagementMemberList]: { name: 'Member Search', parentPath: rp.dataManagementRoot },

      // Data Management / Member Search / Payroll reference
      [rp.dataManagementMemberViewEditRoot]: {
        name: bcNamesMap[rp.dataManagementMemberViewEditRoot],
        parentPath: rp.dataManagementMemberList,
      },

      // Data Management / Member Search / Payroll reference / Membership Details
      [rp.dataManagementMemberViewEditMembershipDetails]: {
        name: 'Membership Details',
        parentPath: rp.dataManagementMemberViewEditRoot,
      },

      // Data Management / Member Search / Payroll reference / Membership Details / Personal Details
      [rp.dataManagementMemberViewEditMembershipPersonalDetails]: {
        name: 'Personal Details',
        parentPath: rp.dataManagementMemberViewEditMembershipDetails,
      },

      // Data Management / Member Search / Payroll reference / Membership Details / Membership Account Details
      [rp.dataManagementMemberViewEditMembershipAccountDetails]: {
        name: 'Membership Account Details',
        parentPath: rp.dataManagementMemberViewEditMembershipDetails,
      },

      // Data Management / Member Search / Payroll reference /  Employment Details
      [rp.dataManagementMemberViewEditEmploymentDetails]: {
        name: 'Employment Details',
        parentPath: rp.dataManagementMemberViewEditRoot,
      },

      // Data Management / Member Search / Payroll reference / Employment Details / General Details
      [rp.dataManagementMemberViewEditEmploymentGeneralDetails]: {
        name: 'General Details',
        parentPath: rp.dataManagementMemberViewEditEmploymentDetails,
      },

      // Data Management / Member Search / Payroll reference / Employment Details / Salary type Details
      [`${rp.dataManagementMemberViewEditEmploymentSalaryDetailsRoot}/*`]: {
        name: 'Salary Details',
        parentPath: rp.dataManagementMemberViewEditEmploymentDetails,
      },

      // Data Management / Member Search / Payroll reference / Employment Details / Add Part Time Details
      [rp.dataManagementMemberViewEditEmploymentSalaryDetailsCreate]: {
        name: 'Add Salary Details',
        parentPath: rp.dataManagementMemberViewEditEmploymentDetails,
      },

      // Data Management / Member Search / Payroll reference / Employment Details / Part Time Details
      [`${rp.dataManagementMemberViewEditEmploymentPartTimeRoot}/*`]: {
        name: 'Part Time Details',
        parentPath: rp.dataManagementMemberViewEditEmploymentDetails,
      },

      // Data Management / Member Search / Payroll reference / Employment Details / Add Part Time Details
      [rp.dataManagementMemberViewEditEmploymentPartTimeDetailsCreate]: {
        name: 'Add Part Time Details',
        parentPath: rp.dataManagementMemberViewEditEmploymentDetails,
      },

      // Data Management / Member Search / Payroll reference / Employment Details / Break In Service Details
      [`${rp.dataManagementMemberViewEditEmploymentBreakInServiceRoot}/*`]: {
        name: 'Break In Service Details',
        parentPath: rp.dataManagementMemberViewEditEmploymentDetails,
      },

      // Data Management / Member Search / Payroll reference / Employment Details / Break In Service Details
      [rp.dataManagementMemberViewEditEmploymentBreakInServiceDetailsCreate]: {
        name: 'Add Break In Service Details',
        parentPath: rp.dataManagementMemberViewEditEmploymentDetails,
      },

      // Data Management / Member Search / Payroll reference/ Contributions
      [rp.dataManagementMemberViewEditContributions]: {
        name: 'Contributions',
        parentPath: rp.dataManagementMemberViewEditRoot,
      },

      // Data Management / Member Search / Payroll reference/ Contributions/ Contribution Amount

      [rp.dataManagementMemberViewEditContributionsAmountList]: {
        name: 'Contribution Amount',
        parentPath: rp.dataManagementMemberViewEditContributions,
      },

      // Data Management / Member Search / Payroll reference/ Contributions/ Contribution Rate

      [rp.dataManagementMemberViewEditContributionsRateList]: {
        name: 'Contribution Rate',
        parentPath: rp.dataManagementMemberViewEditContributions,
      },

      // Data Management / Member Search / Payroll reference/ Audit
      [rp.dataManagementMemberViewEditAuditList]: {
        name: 'Audit',
        parentPath: rp.dataManagementMemberViewEditRoot,
      },

      // Data Management / Member Search / Payroll reference / Audit/ AuditId / Details
      [rp.dataManagementMemberViewEditAuditDetails]: {
        name: bcNamesMap[rp.dataManagementMemberViewEditAuditDetails],
        parentPath: rp.dataManagementMemberViewEditAuditList,
      },

      // Data Management / Scheme audit
      [rp.dataManagementAuditList]: { name: 'Scheme Audit', parentPath: rp.dataManagementRoot },

      // Data Management / Scheme audit/ AuditId / Details
      [rp.dataManagementAuditDetails]: {
        name: bcNamesMap[rp.dataManagementAuditDetails],
        parentPath: rp.dataManagementAuditList,
      },

      // REPORTING
      [rp.reportingRoot]: { name: 'Reporting', parentPath: rp.root },

      // Reporting / Configure Report
      [rp.reportingReportsList]: { name: 'Configure Report', parentPath: rp.reportingRoot },
      [rp.reportingReportsCreate]: {
        name: 'Create New Report',
        parentPath: rp.reportingReportsList,
      },
      [rp.reportingReportsEdit]: {
        name: bcNamesMap[rp.reportingReportsEdit],
        parentPath: rp.reportingReportsList,
      },

      // Reporting / Report History
      [rp.reportingHistoryList]: { name: 'Report History', parentPath: rp.reportingRoot },
      // Reporting / Report History / View/ ExtractId
      [rp.reportingHistoryView]: {
        name: bcNamesMap[rp.reportingHistoryView],
        parentPath: rp.reportingHistoryList,
      },

      // Reporting / Late Payment Report
      [rp.reportingLatePaymentReportList]: { name: 'Late Payment Report', parentPath: rp.reportingRoot },
      // Reporting / Late Payment Report / View / payrollPeriodId
      [rp.reportingLatePaymentReportDetail]: {
        name: bcNamesMap[rp.reportingLatePaymentReportDetail],
        parentPath: rp.reportingLatePaymentReportList,
      },

      // Reporting / Member Request Details
      [rp.reportingRequestsList]: { name: 'Member Request Details', parentPath: rp.reportingRoot },

      // PRODUCT INFO
      [rp.productInfo]: { name: 'Product Information', parentPath: rp.root },
    }),
    [bcNamesMap],
  );

  // Debounce breadcrumbs update to avoid unnecessary updates by redirects
  useEffect(() => {
    if (isHidden) return;

    const delayTimeout = setTimeout(() => {
      setBreadcrumbs(getBreadcrumbs(pathname, config));
    }, 50);
    return () => {
      clearTimeout(delayTimeout);
    };
  }, [config, isHidden, pathname]);

  return isHidden ? null : <Breadcrumbs breadcrumbs={breadcrumbs} />;
}

export default BreadcrumbsTrail;
