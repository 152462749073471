export { history } from './history';

export const RoutePath = {
  root: '/',
  loginRedirect: '/login',
  permissionDenied: '/permission-denied',

  // Configuration
  configurationRoot: '/configuration',

  configurationTemplateDefinitionRoot: '/configuration/template',
  configurationTemplateDefinitionList: '/configuration/template/list',
  configurationTemplateDefinitionEditRoot: '/configuration/template/scheme/:schemeId/edit',
  configurationTemplateDefinitionEditSalaryTypesRoot: '/configuration/template/scheme/:schemeId/edit/salary-types',
  configurationTemplateDefinitionEditSalaryTypesList: '/configuration/template/scheme/:schemeId/edit/salary-types/list',
  configurationTemplateDefinitionEditSalaryTypesCreate:
    '/configuration/template/scheme/:schemeId/edit/salary-types/create',
  configurationTemplateDefinitionEditSalaryTypesEdit:
    '/configuration/template/scheme/:schemeId/edit/salary-types/:schemeSalaryTypeId/edit',
  configurationTemplateDefinitionEditTemplateTypeUpdate:
    '/configuration/template/scheme/:schemeId/edit/upload-template-type',

  configurationGeneralSettingsRoot: '/configuration/general',
  configurationGeneralSettingsList: '/configuration/general/list',
  configurationGeneralSettingsViewEmployer: '/configuration/general/scheme/:schemeId/employer/:employerId/view',
  configurationGeneralSettingsEditEmployer: '/configuration/general/scheme/:schemeId/employer/:employerId/edit',

  configurationValidationsRoot: '/configuration/validations',
  configurationValidationsList: '/configuration/validations/list',
  configurationValidationViewRoot: '/configuration/validations/:validationId/view',
  configurationValidationViewDetail: '/configuration/validations/:validationId/view/detail',
  configurationValidationViewAudit: '/configuration/validations/:validationId/view/audit',
  configurationValidationEditRoot: '/configuration/validations/:validationId/edit',
  configurationValidationEditDetail: '/configuration/validations/:validationId/edit/detail',
  configurationValidationEditAudit: '/configuration/validations/:validationId/edit/audit',

  configurationPayrollsRoot: '/configuration/payrolls',
  configurationPayrollsList: '/configuration/payrolls/list',
  configurationPayrollsAdd: '/configuration/payrolls/add',
  configurationPayrollViewRoot: '/configuration/payrolls/:payrollId/view',
  configurationPayrollViewDetail: '/configuration/payrolls/:payrollId/view/detail',
  configurationPayrollViewPeriod: '/configuration/payrolls/:payrollId/view/period',
  configurationPayrollEditRoot: '/configuration/payrolls/:payrollId/edit',
  configurationPayrollEditDetail: '/configuration/payrolls/:payrollId/edit/detail',
  configurationPayrollEditPeriod: '/configuration/payrolls/:payrollId/edit/period',

  // Data Management
  dataManagementRoot: '/data-management',
  dataManagementUploadHistoryList: '/data-management/upload-history/list',
  dataManagementUploadHistoryUploadData: '/data-management/upload-history/upload-data',
  dataManagementUploadHistoryDetailsRoot: '/data-management/upload-history/:fileId/details',
  dataManagementUploadHistoryDetailsSummary: '/data-management/upload-history/:fileId/details/summary',
  dataManagementUploadHistoryDetailsAudit: '/data-management/upload-history/:fileId/details/audit',
  dataManagementValidationHelperList: '/data-management/validation-helper',

  dataManagementMemberList: '/data-management/member/list',
  dataManagementMemberViewEditRoot: '/data-management/member/:employeeId/:action',
  dataManagementMemberViewEditMembershipDetails: '/data-management/member/:employeeId/:action/membership',
  dataManagementMemberViewEditMembershipPersonalDetails:
    '/data-management/member/:employeeId/:action/membership/personal-details',
  dataManagementMemberViewEditMembershipAccountDetails:
    '/data-management/member/:employeeId/:action/membership/account-details',
  dataManagementMemberViewEditEmploymentDetails: '/data-management/member/:employeeId/:action/employment',
  dataManagementMemberViewEditEmploymentGeneralDetails:
    '/data-management/member/:employeeId/:action/employment/general-details',

  dataManagementMemberViewEditEmploymentSalaryDetailsRoot:
    '/data-management/member/:employeeId/:action/employment/salary-details',
  dataManagementMemberViewEditEmploymentSalaryDetailsList:
    '/data-management/member/:employeeId/:action/employment/salary-details/list',
  dataManagementMemberViewEditEmploymentSalaryDetailsCreate:
    '/data-management/member/:employeeId/:action/employment/salary-details/create',
  dataManagementMemberViewEditEmploymentSalaryDetailsEdit:
    '/data-management/member/:employeeId/:action/employment/salary-details/:salaryDetailId/edit',

  dataManagementMemberViewEditEmploymentPartTimeRoot:
    '/data-management/member/:employeeId/:action/employment/part-time',
  dataManagementMemberViewEditEmploymentPartTimeList:
    '/data-management/member/:employeeId/:action/employment/part-time/list',
  dataManagementMemberViewEditEmploymentPartTimeDetailsCreate:
    '/data-management/member/:employeeId/:action/employment/part-time/create',
  dataManagementMemberViewEditEmploymentPartTimeDetailsView:
    '/data-management/member/:employeeId/:action/employment/part-time/:partTimeId/view/details',
  dataManagementMemberViewEditEmploymentPartTimeDetailsEdit:
    '/data-management/member/:employeeId/:action/employment/part-time/:partTimeId/edit/details',

  dataManagementMemberViewEditEmploymentBreakInServiceRoot:
    '/data-management/member/:employeeId/:action/employment/break-in-service',
  dataManagementMemberViewEditEmploymentBreakInServiceList:
    '/data-management/member/:employeeId/:action/employment/break-in-service/list',
  dataManagementMemberViewEditEmploymentBreakInServiceDetailsCreate:
    '/data-management/member/:employeeId/:action/employment/break-in-service/create',
  dataManagementMemberViewEditEmploymentBreakInServiceDetailsView:
    '/data-management/member/:employeeId/:action/employment/break-in-service/:breakInServiceId/view/details',
  dataManagementMemberViewEditEmploymentBreakInServiceDetailsEdit:
    '/data-management/member/:employeeId/:action/employment/break-in-service/:breakInServiceId/edit/details',

  dataManagementMemberViewEditContributions: '/data-management/member/:employeeId/:action/contributions',
  dataManagementMemberViewEditContributionsAmountList:
    '/data-management/member/:employeeId/:action/contributions/amount',
  dataManagementMemberViewEditContributionsRateList: '/data-management/member/:employeeId/:action/contributions/rate',

  dataManagementMemberViewEditAuditRoot: '/data-management/member/:employeeId/:action/audit',
  dataManagementMemberViewEditAuditList: '/data-management/member/:employeeId/:action/audit/list',
  dataManagementMemberViewEditAuditDetails: '/data-management/member/:employeeId/:action/audit/:auditId/details',

  dataManagementAuditRoot: '/data-management/audit',
  dataManagementAuditList: '/data-management/audit/list',
  dataManagementAuditDetails: '/data-management/audit/:auditId/details',

  // Analytics & Reports
  reportingRoot: '/reporting',
  reportingReportsRoot: '/reporting/reports',
  reportingReportsList: '/reporting/reports/list',
  reportingReportsCreate: '/reporting/reports/create',
  reportingReportsEdit: '/reporting/reports/edit/:reportId',

  reportingHistoryRoot: '/reporting/history',
  reportingHistoryList: '/reporting/history/list',
  reportingHistoryView: '/reporting/history/view/:extractId',

  reportingLatePaymentReportRoot: '/reporting/late-payment-report',
  reportingLatePaymentReportList: '/reporting/late-payment-report/list',
  reportingLatePaymentReportDetail: '/reporting/late-payment-report/view/:payrollPeriodId',

  reportingRequestsList: '/reporting/requests/list',

  // Product Information
  productInfo: '/product-info',

  // Audit
  audit: '/audit',
};

export { GuardedRoute } from './GuardedRoute';
